var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"question-detail",class:_vm.showQuestion
      ? 'row question-detail show-FullQuestion'
      : 'row question-detail'},[(
      _vm.showPassage &&
        _vm.question.sat_passage &&
        _vm.question.sat_passage.content !== ''
    )?_c('div',{staticClass:"col-6 p-0",staticStyle:{"border-right":"2px solid #212529"}},[_c('div',{staticClass:"question-detail-passage question-content-title",domProps:{"innerHTML":_vm._s(_vm.changeColor(_vm.question.sat_passage.content))}})]):_vm._e(),_c('div',{class:_vm.showPassage &&
      _vm.question.sat_passage &&
      _vm.question.sat_passage.content !== ''
        ? 'col-6 p-0'
        : 'col-12 p-0'},[_c('div',{staticClass:"question-detail-question"},[_c('div',{staticClass:"question-detail-question-order"},[(_vm.order)?_c('kbd',[_vm._v(" "+_vm._s(_vm.order)+" ")]):_vm._e(),(_vm.isAdmin)?_c('router-link',{staticClass:"edit-button",attrs:{"to":{
            name: 'SatEditQuestion',
            query: {
              questionId: _vm.question.id
            }
          }}},[_c('i',{staticClass:"fa fa-edit"})]):_vm._e()],1),(_vm.showQuestion || _vm.showOptions || _vm.showAnswer)?_c('div',{staticClass:"question-detail-question-content question-content-title"},[(_vm.showQuestion)?_c('div',[_c('b',{domProps:{"innerHTML":_vm._s(_vm.changeColor(_vm.question.content))}})]):_vm._e(),_c('div',[(_vm.question.type === 'default' && _vm.showOptions)?_c('ul',{staticClass:"ivy-question-options"},_vm._l((_vm.question.sat_options),function(option,index){return _c('li',{key:index,staticClass:"option"},[(_vm.question.sat_answers)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswer),expression:"showAnswer"}],staticStyle:{"width":"1.5rem"}},[_c('span',{staticClass:"text-success"},[(_vm.isAnswer(_vm.question.sat_answers, option.letter))?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e()])]):_vm._e(),_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(_vm._s(((option.letter) + ") ")))]),_c('div',{staticClass:"optionText",domProps:{"innerHTML":_vm._s(_vm.changeColor(option.title))}})])}),0):_vm._e(),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswer),expression:"showAnswer"}],staticClass:"mt-2"},[_c('b',[_vm._v("Answer:")]),_vm._l((_vm.question.sat_answers),function(answer,index){return _c('span',{key:index,staticClass:"ml-1"},[_vm._v(" "+_vm._s(answer.answers[0])+" "),(index < _vm.question.sat_answers.length - 1)?_c('span',[_vm._v(" , ")]):_vm._e()])})],2)]],2),(_vm.questionTags(_vm.question.sat_question_tags))?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLabels),expression:"showLabels"}]},_vm._l((_vm.questionTags(
                _vm.question.sat_question_tags
              )),function(tag_analysis,key,index){return _c('div',{key:index,staticClass:"pb-2"},[_c('b',[_vm._v(_vm._s(key))]),_vm._v(": "),_vm._l((tag_analysis),function(tag){return _c('el-tag',{key:tag.id,staticClass:"ivy-tag",attrs:{"type":"success","size":"small"}},[_c('b',[_vm._v(_vm._s(tag.name))])])})],2)}),0)]:_vm._e()],2):_vm._e()])]),(
      _vm.showExplanation && _vm.question.explanation && _vm.question.explanation !== ''
    )?_c('div',{staticClass:"col-12 p-0",staticStyle:{"border-top":"2px solid #212529"}},[_c('div',{staticClass:"question-detail-explanation"},[_c('b',{staticClass:"mb-1"},[_vm._v("Explanation:")]),(_vm.isValidUrl(_vm.question.explanation))?_c('div',{staticClass:"video-box"},[_c('iframe',{attrs:{"src":_vm.setUrl(_vm.question.explanation)}})]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.question.explanation)}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }