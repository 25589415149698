<template>
  <div class="p-4">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ PageTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'SatComposePracticeDetailAll', query: $route.query }"
          >
            Browse
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          PDF
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-alert type="warning" show-icon class="mb-3">
      <div style="font-size: 14px;" class="mb-2">
        <b>Notice:</b>
        This "browse" feature is only available for teachers to help you view
        all questions on the same page, which may be helpful for you during your
        preparation or the actual class. You may also use the "Download PDF"
        button to create PDFs for yourself, but you are
        <strong>NOT allowed</strong> to distribute electronic or physical copies
        of the PDF to students. Students should practice electronically and can
        view the test and all the questions from their
        <router-link :to="{ name: 'SATResults' }">Results</router-link> page
        after completing the practice test.
      </div>
    </el-alert>
    <el-button
      type="success"
      @click="downloadPDF('#content', PageTitle)"
      style="width: 100%;"
    >
      <i class="fas fa-download"></i>
      Download PDF
    </el-button>
    <div class="mt-4 mb-3">
      <el-checkbox
        v-model="showPassage"
        label="Passage"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showQuestion"
        label="Entire Question"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showOptions"
        label="Choices"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showAnswer"
        label="Answer"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showExplanation"
        label="Explanation"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showLabels"
        label="Domain and Skills"
        size="medium"
        border
      ></el-checkbox>
    </div>
    <div v-if="practiceType == 'adaptivePractices'">
      <el-radio-group v-model="adaptiveIndex">
        <el-radio-button
          :label="getCompanyExamTitle(practice.exam.title)"
          v-for="(practice, index) in adaptivePracticesArr"
          :key="practice.id"
          :value="index"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div ref="questions-all">
      <h4>
        {{ getCompanyExamTitle(composePractice.exam.title) }}
      </h4>
      <hr class="separate-line" />
      <div ref="targetDom" id="content">
        <template v-if="composePractice.compose_practice_practices.length > 0">
          <div
            v-for="(practice,
            index) in composePractice.compose_practice_practices"
            :key="practice.id"
            v-show="sectionIndex - 1 === index || !sectionIndex"
            class="content p-4"
            :id="`content${index}`"
            :title="getCompanyExamTitle(practice.exam.title)"
          >
            <div class="small" v-if="false">
              <PracticeCard
                :practice="practice"
                :order="index + 1"
              ></PracticeCard>
            </div>
            <div class="small">
              <h4>
                {{ practice.exam.title }}
              </h4>
              <hr />
            </div>
            <div
              class="small"
              style="padding-bottom: 2rem;"
              v-for="(question, index) in practice.exam.exam_questions"
              :key="question.id"
            >
              <QuestionTableDetail
                :isAdmin="isRoleAdmin()"
                :question="question.question"
                :order="index + 1"
                :showQuestion="showQuestion"
                :showPassage="showPassage"
                :showOptions="showOptions"
                :showAnswer="showAnswer"
                :showExplanation="showExplanation"
                :showLabels="showLabels"
              ></QuestionTableDetail>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import PracticeCard from "@/views/satComposePractice/components/Practice.vue";
// import QuestionCard from "@/views/satComposePractice/components/QuestionCard.vue";
import QuestionTableDetail from "@/views/allQuestions/QuestionTableDetail";
import { instant } from "@ivy-way/material";
import $ from "jquery";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, PracticeCard, QuestionTableDetail },

  mixins: [Common, roleMixin],

  props: [],
  data() {
    return {
      showPassage: true,
      showQuestion: true,
      showOptions: true,
      showAnswer: false,
      showExplanation: false,
      showLabels: false,
      isNew: 1,
      showCreateNewSectionDialog: false,
      breakTime: [],
      composePractice: {
        test_id: null,
        test_type: "",
        exam: {
          title: null
        },
        compose_practice_practices: []
      },
      adaptivePracticesArr: [],
      adaptiveIndex: 0,
      practices: [],
      subjects: [],
      subject_id: null,
      keyWord: "",
      pageSize: 0,
      page: 0,
      resultsTotal: 0
    };
  },
  computed: {
    PageTitle() {
      let title = this.composePractice.exam.title
        ? this.getCompanyExamTitle(this.composePractice.exam.title)
        : "";
      return title;
    },
    instant() {
      return instant;
    },
    id() {
      return this.$route.query.id;
    },
    sectionIndex() {
      return this.$route.query.section;
    },
    practiceType() {
      return this.$route.query.practiceType;
    }
  },
  watch: {
    showPassage() {
      this.initMathJaxPlugin();
    },
    showExplanation() {
      this.initMathJaxPlugin();
    },
    // showAnswer() {
    //   if (this.showAnswer) {
    //     this.showOptions = true;
    //   }
    // },
    showOptions() {
      this.initMathJaxPlugin();
    },
    showQuestion() {
      this.initMathJaxPlugin();
    },
    questions() {
      this.initMathJaxPlugin();
    }
  },

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    if (this.id && Number(this.id) > 0) {
      await this.getComposePracticeDetail();
      await this.initMathJaxPlugin();
    }
    this.$nextTick(async () => {
      this.setImg();
    });
    let vm = this;
    window.onkeydown = function(e) {
      // 屏蔽ctrl+p 打印
      if (e.ctrlKey && e.keyCode == 80) {
        vm.$message({
          dangerouslyUseHTMLString: true,
          message:
            "Please use the  <i class='fas fa-download'></i> <b>Download PDF</b> button below to create a PDF file for this test.",
          type: "warning"
        });
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    };
  },

  methods: {
    setImg() {
      $(".small img").each(function() {
        if ($(this).attr("src") !== "") {
          $(this).attr("src", $(this).attr("src") + "?" + new Date().getTime());
        }
        $(this).attr("crossorigin", "anonymous");
      });
    },
    isSplit(nodes, index, pageHeight) {
      return (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      );
    },
    getFooterElement(remainingHeight, fillingHeight = 36) {
      let newNode = document.createElement("div");
      newNode.style.background = "#ffffff";
      newNode.style.width = "calc(100% + 8px)";
      newNode.style.marginLeft = "-4px";
      newNode.style.marginBottom = "0px";
      newNode.style.height = remainingHeight + fillingHeight + "px"; // pdf截断需要一个空白位置
      return newNode;
    },
    outPutPdfFn(ele, title) {
      let vm = this;
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;

      vm.$nextTick(async () => {

        $(".content").each(async function() {
          // vm.getPdf("#" + $(this).attr("id"), $(this).attr("title"));
          // vm.outPutPdfFn("#" + $(this).attr("id"), $(this).attr("title"));
          let target = $(this)[0];
          let pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
          let lableListID = $(`#${$(this).attr("id")} .small`);
          console.log(lableListID);
          for (let i = 0; i < lableListID.length; i++) {
            let multiple = Math.ceil(
              (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
                pageHeight
            );

            if (vm.isSplit(lableListID, i, multiple * pageHeight)) {
              console.log(multiple + "Page: " + multiple * pageHeight);
              console.log(vm.isSplit(lableListID, i, multiple * pageHeight));

              console.log(i + " offsetTop:" + lableListID[i].offsetTop);
              console.log(i + " offsetHeight:" + lableListID[i].offsetHeight);

              let divParent = lableListID[i].parentNode; // 获取该div的父节点
              let _H =
                multiple * pageHeight -
                (lableListID[i].offsetTop + lableListID[i].offsetHeight);

              console.log(_H);
              let newNode = vm.getFooterElement(_H);
              let next = lableListID[i + 1] ? lableListID[i + 1] : false; // 获取div的下一个兄弟节点
              // 判断兄弟节点是否存在
              if (next) {
                // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                divParent.insertBefore(newNode, next);
              } else {
                // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                divParent.appendChild(newNode);
                console.log("next");
              }
              // divParent.appendChild(newNode);
            }
          }
        });

        await vm.getPdf("content", title);
      });
    },
    downloadPDF(ele, title) {
      let vm = this;
      vm.outPutPdfFn(ele, title);
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-all"]]);
      });
    },
    addPassage(index) {
      if (this.composePractice.compose_practice_practices.length === 4) {
        this.$message({
          message: "No more than 4 Subjects",
          type: "warning"
        });
      } else {
        this.composePractice.compose_practice_practices.push(
          this.practices[index]
        );
      }
    },
    async getComposePracticeDetail() {
      const res = await SAT.getEditorComposePracticeDetail(this.id);
      this.composePractice = {
        ...res.compose_practice,
        compose_practice_practices: res.compose_practice.practices
      };
    }
  }
};
</script>

<style scoped>
#content {
  width: 1184.56px;
  margin: 0 auto;
  position: relative;
}
.content {
  width: 1184.56px;
  margin: 0 auto;
  position: relative;
}
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}
.questions-list {
  height: 600px;
  overflow: auto;
  padding: 1rem;
  /* border: 2px solid var(--themeColor); */
}
</style>
